import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '../plugins/i18n';

import Landing from '../views/AeonLanding.vue';
const NotFound = { template: '<div>not found</div>' };

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`,
  },  
  // {
  //   path: `${process.env.BASE_URL}`,
  //   redirect: `${process.env.BASE_URL}${i18n.locale}`,
  // },  
  {
    path: '/:lang',
    // path: `${process.env.BASE_URL}:lang`,
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '/',
        name: 'landing',
        component: Landing,
      },
      {
        path: 'charts',
        name: 'comparecharts',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "comparecharts" */ '../views/AeonComparisonCharts.vue'),
      },
      {
        path: 'climate-viz',
        name: 'weatherviz',
        component: () => import(/* webpackChunkName: "weatherviz" */ '../views/AeonClimateViz.vue'),
      },
      { path: '*', component: NotFound },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
