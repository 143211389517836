<template>
  <v-col cols="5" md="2" lg="3" offset="4" offset-md="0" order="2" order-md="last" class="text-right">
    <v-btn-toggle mandatory dense>
      <v-btn
        color="#343768"
        outlined
        elevation="0"
        @click.prevent="setLocale('en')"
        :class="locale === 'en' ? 'button--active' : 'button--notactive'"
      >
        <span class="text-body-sm text--aeon-navy">EN</span>
      </v-btn>
      <v-btn
        color="#343768"
        outlined
        elevation="0"
        @click.prevent="setLocale('ar')"
        :class="locale === 'ar' ? 'button--active' : 'button--notactive'"
      >
        <span class="text-body-sm text--aeon-navy">عربي</span>
      </v-btn>
    </v-btn-toggle>
  </v-col>
</template>

<script>
import { setDocumentDirectionPerLocale, setDocumentLang } from '@/plugins/document';

export default {
  name: 'UiLanguageToggle',
  data() {
    return {
      locale: '',
    };
  },
  mounted() {
    this.locale = window.location.pathname.includes("/ar/") ? 'ar' : 'en';
    this.$watch(
      '$i18n.locale',
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale || newLocale === 'index.html') {
          return;
        }
        setDocumentLang(newLocale);
        setDocumentDirectionPerLocale(newLocale);
      },
      { immediate: true }
    );
  },
  methods: {
    setLocale(locale) {
      this.locale = locale;
      this.$i18n.locale = locale;
      this.$router.push({
        params: { lang: locale },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.button--active.v-btn--outlined {
  background-color: #343768;
  span {
    color: #ffffff;
  }
}

.button--notactive.v-btn--outlined {
  span.v-btn__content {
    color: #ffffff;
  }
}

.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 0 !important;
}
</style>
