export function setDocumentDirectionPerLocale(locale) {
  const element = document.getElementsByClassName('dirchange');
  if (element) {
    for (let i = 0; i < element.length; i++) {
      element[i].style.direction = locale === 'ar' ? 'rtl' : 'ltr';
    }
  }
}

export function setDocumentLang(lang) {
  document.documentElement.lang = lang;
  document.documentElement.style.setProperty(
    '--font-family',
    lang === 'en' ? "'Theinhardt', sans-serif'" : "'29LT Zeyn', sans-serif"
  );
}