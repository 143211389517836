<template>
  <div class="page-landing">
    <nav class="page-landing--nav px-md-16 pt-md-12">
      <v-row justify="end" align="center">
        <UiLanguageToggle />
      </v-row>
    </nav>

    <section class="page-landing--content">
      <v-container fill-height>
        <v-row justify="center" class="text--aeon-navy text-center">
          <v-col cols="12">
            <a href="https://aeoncollective.org/" target="_blank">
              <img src="@/assets/img/aeon-logo-grey.png" alt="Aeon logo" width="154px" class="mb-14" />
            </a>

            <h1 class="text-title mb-4">
              {{ $t('pageLanding.title') }}
            </h1>
          </v-col>

          <v-col cols="12" md="8" lg="7" xl="6">
            <h2 class="text-body font-weight-regular mb-8 mb-md-14" v-html="$t('pageLanding.subtitle')" />

            <div v-if="!$vuetify.breakpoint.xsOnly" class="d-flex flex-row justify-center align-center">
              <v-btn :to="`/${$i18n.locale}/climate-viz`" outlined elevation="0" color="#343768" class="py-6 px-4">
                <span class="text-body mb-0 text--aeon-navy">{{ $t('pageLanding.button-2') }}</span>
              </v-btn>

              <hr class="navbar-line" />

              <v-btn :to="`/${$i18n.locale}/charts`" outlined elevation="0" color="#343768" class="py-6 px-4">
                <span class="text-body mb-0 text--aeon-navy">{{ $t('pageLanding.button-3') }}</span>
              </v-btn>
            </div>

            <div v-else class="d-flex flex-column justify-center align-center">
              <v-btn :to="`/${$i18n.locale}/climate-viz`" outlined elevation="0" color="#343768" class="py-6 px-4">
                <span class="text-body mb-0 text--aeon-navy">{{ $t('pageLanding.button-2') }}</span>
              </v-btn>

              <hr class="navbar-line" />

              <v-btn :to="`/${$i18n.locale}/charts`" outlined elevation="0" color="#343768" class="py-6 px-4">
                <span class="text-body mb-0 text--aeon-navy">{{ $t('pageLanding.button-3') }}</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import UiLanguageToggle from '@/components/ui/UiLanguageToggle';

export default {
  name: 'AeonLanding',
  components: {
    UiLanguageToggle,
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: unset;
  color: #343768;
}

.page-landing {
  height: 100vh;
  // padding: 96px 0;
  background: url('~@/assets/img/aeon-landing-bg.svg'), linear-gradient(180deg, #aad4e7 0%, #f9ece8 100%);

  &--content {
    height: calc(100% - 148px);
  }

  .v-btn {
    text-transform: unset !important;
    min-width: 150px;
    width: 200px;
  }

  .navbar-line {
    max-width: 96px;
    min-width: 48px;
    border: none;
    border-top: 1px dashed #343768;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      height: 25px;
      border: unset;
      width: 1px;
      min-width: unset;
      max-width: unset;
      border-left: 1px dashed #343768;
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: 48px 4px;
  }
}
</style>
